import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Henrik Sporh Christiansen', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'My projects', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi! I\'m ',
  name: 'Henrik Sporh Christiansen',
  subtitle: 'and here you can see my projects',
  cta: 'Take a look',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Entrepreneur at heart :)',
  paragraphTwo: 'Since 2005 I have started and run companies. My focus has been on online marketing and WordPress websites. Currently I run a one man agency called Anvil while making side projects.',
  paragraphThree: 'I\'m always open to new ideas so feel free to write me.',
  resume: 'https://www.linkedin.com/in/henriksporh/', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'migraine-calendar.jpg',
    title: 'Migraine Calendar',
    info: 'A free online migraine calendar.',
    info2: 'A migraine calendar that helps you keep track of triggers and patterns. It is easy to use, simple and free. Keep track where ever you are.',
    url: 'https://migraine-calendar.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'anvil.jpg',
    title: 'Anvil',
    info: 'The world\'s smallest web agency.',
    info2: 'I make WordPress websites. I work closely with my clients and try to understand their business. I have more than 15 years of online business experience, that I try to put into the projects.',
    url: 'https://anvil.dk/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'skywalkr.jpg',
    title: 'Skywalkr',
    info: 'High speed WordPress Hosting.',
    info2: 'Danish WordPress hosting in the cloud. Incredible speed and reliability.',
    url: 'https://www.skywalkr.io/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'become.jpg',
    title: 'Become',
    info: 'Online marketing.',
    info2: 'Since 2011 we have helped more than 600 companies with Google Ads, Facebook Ads, Analytics and WordPress websites. We started out as Beruf, but in 2020 we merged with Compell from Aarhus and now er are Become. Same services, but now we cover the whole of Denmark with 35+ employees and 3 offices.',
    url: 'https://www.become.dk/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'mimer-metrics.jpg',
    title: 'Mimer Metrics',
    info: 'Easy reporting for busy marketers.',
    info2: 'Save time with our Dashboards & Reports.',
    url: 'https://mimer.io/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'affiliate-netvaerk.jpg',
    title: 'Affiliate Netværk',
    info: 'Guide to affiliate marketing in Denmark.',
    info2: 'En liste over affiliate netværk i Danmark samt guides, så du kan komme godt i gang med affiliate marketing.',
    url: 'https://affiliatenetvaerk.dk/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Can I help? Feel free to write me.',
  btn: 'Contact me',
  email: 'hc@anvil.dk',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/sporh',
    },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/henriksporh/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/sporh',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};